/* login.css */

.login-container {
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    height: 100vh; /* Full viewport height */
    background-color: #fff3e0; /* Light orange background color */
    margin: 0; /* Remove default margin */
    font-family: 'Poppins', sans-serif; /* Use a clean font */
}

h2 {
    margin-bottom: 20px; /* Space below the heading */
    color: #ff5722; /* Darker orange color for the heading */
}

.login-form {
    background: #ffffff; /* White background for the form */
    padding: 20px; /* Padding inside the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
    width: 300px; /* Fixed width for the form */
}

.form-group {
    margin-bottom: 15px; /* Space between form elements */
}

label {
    margin-bottom: 5px; /* Space below label */
    display: block; /* Block display for label */
    color: #333; /* Darker color for the label */
}

.input-field {
    width: 100%; /* Full width for input */
    padding: 10px; /* Padding inside input */
    border: 1px solid #ff5722; /* Orange border */
    border-radius: 4px; /* Rounded corners */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.error-message {
    color: red; /* Red color for error messages */
    margin-top: 10px; /* Space above error message */
}

.submit-button {
    background-color: #ff5722; /* Orange background color */
    color: white; /* White text color */
    padding: 10px; /* Padding inside button */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    width: 100%; /* Full width for button */
    font-size: 16px; /* Font size for button */
}

    .submit-button:hover {
        background-color: #e64a19; /* Darker shade of orange on hover */
    }
